<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/newList' }"
        >新闻列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{
        editId ? "编辑新闻" : "新增新闻"
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form
      :model="NewInfo"
      :rules="NewInfoFormRules"
      ref="NewInfoFormRef"
      label-width="100px"
      style="margin-left: 5%; margin-right: 5%"
    >
      <el-form-item label="新闻类型：" prop="type" style="width: 60%">
        <ELselect
          v-model="NewInfo.type"
          :options="Types"
          :optionKey="Typeskeys"
          Splaceholder="请选择"
          @change="changeVal"
        ></ELselect>
      </el-form-item>

      <el-form-item label="新闻标题：" prop="title" style="width: 60%">
        <el-input v-model.trim="NewInfo.title" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item
        label="新闻图标："
        prop="logoImage.icon"
        style="width: 60%"
        :rules="NewInfoFormRules.logo"
      >
        <UploadOneIcon :iconInfo="NewInfo.logoImage"></UploadOneIcon>
      </el-form-item>

      <el-form-item label="新闻内容：" style="width: 90%" prop="xmlContent">
        <div style="color: red; text-align: left">
          注意**：非在线编辑的情况： 导入文件（推荐，目前仅支持word）
          <!-- 2、复制粘贴（本地的图文或多图模式，需确保C:/Users/admin/Pictures目录下存在图片） -->
        </div>
        <TinymceDom @input="Tinymceinputs"> </TinymceDom>
      </el-form-item>

      <el-form-item label="发布时间：" prop="eventTime" style="width: 60%">
        <el-date-picker
          v-model="NewInfo.eventTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%; text-align: left"
        >
        </el-date-picker>
      </el-form-item>

      <el-button @click="cancleAdd" v-show="editId">取消</el-button>
      <el-button type="primary" @click="confirmAdd">确定</el-button>
    </el-form>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import TinymceDom from '@/components/myself/tinymceEd/TinymceDom.vue'
import { addNews, editNews, } from '@/api/News.js'
import { UploadOssResource } from '@/libs/ossUpload/getUrl'
import { getDetailInfo, getUrlContent } from '@/libs/commonApi.js'
import ListenEvent from '@/libs/ListenEvent.js'
import UploadOneIcon from '@/components/myself/UploadCommon/UploadOne.vue'
import { validatorInput } from '@/components/myself/speciaChaFilt.js'

export default {
  components: { ELselect, TinymceDom, UploadOneIcon },
  data () {
    return {
      NewInfo: {
        title: '',
        type: '',
        xmlContent: '',
        url: '',
        logoImage: {
          icon: '',
          iconSize: '',
          IconObject: {},
          acceptImg: ".png,.jpg,.jpeg,.gif",
          type: 'news'
        },
        eventTime: '',
      },
      NewInfoFormRules: {
        title: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
          {
            min: 5, max: 50, message: '长度必须在5-50个字符间', trigger: 'blur'
          },
          { validator: validatorInput, trigger: 'blur' },
        ],
        type: [
          { required: true, message: '内容不能为空', trigger: 'blur' },

        ],
        xmlContent: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        logo: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },
      Typeskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },
      Types: [
        {
          label: '公司新闻',
          value: 1
        }, {
          label: '产品动态',
          value: 2
        }],

      editId: '',
      typeShowDlag: false,
      typeShowIndex: ''
    }
  },
  mounted () {
    this.editId = this.$route.query.id
    if (this.editId) {
      this.getDetail()
    }
  },
  methods: {
    async getDetail () {
      const res = await getDetailInfo(this.editId)
      this.NewInfo.title = res.title
      this.typeShowIndex = res.type
      this.NewInfo.type = res.type == '1' ? this.Types[0].label : this.Types[1].label
      this.NewInfo.logoImage.icon = res.coverUrl
      this.NewInfo.eventTime = res.eventTime
      let urlContent = await getUrlContent(res.url)
      ListenEvent.$emit('msgShow', urlContent)

    },
    // tinymec输入的内容
    Tinymceinputs (text) {
      if (text.search('<p') !== -1) {
        this.NewInfo.xmlContent = text
      } else {
        this.NewInfo.xmlContent = ''
      }
    },

    async exportHtml (data) {
      let fileContent = new File([data], this.NewInfo.title + '.html', { type: '' })
      const res = await UploadOssResource(fileContent, 'news')
      return res.url
    },
    changeVal (val) {
      this.typeShowDlag = true
      this.NewInfo.type = val
    },

    async confirmAdd () {
      this.$refs.NewInfoFormRef.validate(async valid => {
        if (valid) {
          this.NewInfo.url = await this.exportHtml(this.NewInfo.xmlContent)
          if (!this.editId) {
            let quiese = {}
            if (this.NewInfo.eventTime) {
              quiese = { url: this.NewInfo.url, title: this.NewInfo.title, type: this.NewInfo.type, coverUrl: this.NewInfo.logoImage.icon, eventTime: this.NewInfo.eventTime }
            } else {
              quiese = { url: this.NewInfo.url, title: this.NewInfo.title, type: this.NewInfo.type, coverUrl: this.NewInfo.logoImage.icon }
            }
            const res = await addNews(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          } else {
            let quiese = {}
            if (this.NewInfo.eventTime) {
              quiese = { id: this.editId, url: this.NewInfo.url, title: this.NewInfo.title, type: this.typeShowDlag ? this.NewInfo.type : this.typeShowIndex, coverUrl: this.NewInfo.logoImage.icon, eventTime: this.NewInfo.eventTime }
            } else {
              quiese = { id: this.editId, url: this.NewInfo.url, title: this.NewInfo.title, type: this.typeShowDlag ? this.NewInfo.type : this.typeShowIndex, coverUrl: this.NewInfo.logoImage.icon }
            }

            const res = await editNews(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
          }

          this.$message.success('操作成功')
          this.$router.push('/newList')
        } else {
          return this.$message.error('请输入新闻内容')
        }
      })
    },

    cancleAdd () {
      this.$router.push('/newList')
    }
  }
}
</script>